export const styles = {
  gridContainer: {
    display: 'flex',
    margin: 'auto',
    marginTop: 40,
    marginBottom: 40,
    width: "82.5%" ,
  },
  flex: {
    display: "flex"
  },
  button: {
    marginTop: 25,
    width: "100%",
    textTransform: "none",
  }
}
