import React, { Component } from 'react';

// material ui core
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';


// static
import bullseyeLogo from "../../static/target_logo.svg"

import withStyles from '@mui/styles/withStyles';
import { styles } from './NavBar.styles.js';

const ITEM_HEIGHT = 48;


class MainNav extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };


  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { classes } = this.props;

    return (
      <AppBar className={classes.appBar}>
        <Hidden mdDown >

          <Toolbar className={classes.largeSpacing}>
          <IconButton className={classes.menuButton} href={"/"} size="large">
            <img className={classes.bullseyeLogo} src={bullseyeLogo} alt="bullseye"/>
          </IconButton>


            <Typography variant="h5" color="inherit" >
              Open Source 
            </Typography>




          </Toolbar>
        </Hidden>

      <Hidden mdUp >
        <Toolbar className={classes.smallSpacing}>
          <IconButton className={classes.menuButton} href={"/"} size="large">
            <img className={classes.bullseyeLogo} src={bullseyeLogo} alt="bullseye"/>
          </IconButton>


            <Typography variant="h5" color="inherit" >
              Open Source
            </Typography>

            <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
          </Menu>


        </Toolbar>

      </Hidden>


      </AppBar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MainNav);
