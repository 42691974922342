import React, { Component } from 'react';
import AboutCFC from './AboutCFC.js';
// import Explore from './Explore.js';
import AllProjects from './AllProjects.js';
import ContactUs from './ContactUs.js';

import { all_projects_content } from './Content.js'


class Security extends Component{
  render(){
    return(
      <div>
        <AboutCFC/>
        <AllProjects content={all_projects_content}/>
        <ContactUs/>
      </div>
    )
  }
}

export default Security;
