import React, { Component } from 'react';

// custom components
import FeaturedPanel from '../components/FeaturedPanel/FeaturedPanel.js';
import SubsiteContentCard from '../components/FeaturedPanel/SubsiteContentCard.js';
import AllProjects from '../components/AllProjects/AllProjects.js';

import MissionStatement from '../components/MissionStatement/MissionStatement.js';


// carousel slides
import ProjectImage from '../components/Carousel/CardTemplates/ProjectImage/ProjectImage.js';
import CarouselCardContainer from '../components/Carousel/CarouselCardContainer.js';


// static content
import WorkSomeWhereYouLove from '../content/images/careers.png';
import GreaseCarouselImage from '../content/images/Grease-carousel.png';
import TechBlogCarouselImage from '../content/images/TechBlog carousel.png';
import TargetCFC from '../content/images/tgcfc.png';
import VelaLogo from '../content/images/VelaLogo.png';

import withStyles from '@mui/styles/withStyles';
import { styles } from './Home.styles.js';

class Home extends Component{
  constructor(props){
    super(props)
    this.changeTopic = this.changeTopic.bind(this);
    this.state = {
      current_topic: "all",
    }
  }

  changeTopic(new_topic){
    this.setState({current_topic: new_topic})
  }

  render(){

    const { classes } = this.props;

    return(
      <div>
      <div className={classes.carousel}>
        <CarouselCardContainer>
        {/*Child compoents here are the carousel slides*/}
          <ProjectImage source={VelaLogo} link="https://go-vela.github.io/docs/"/>
          <ProjectImage source={TargetCFC} link="https://opensource.target.com/security"/>
          <ProjectImage source={GreaseCarouselImage} link="https://www.github.com/target/grease"/>
          <ProjectImage source={TechBlogCarouselImage} link="https://tech.target.com"/>
          <ProjectImage source={WorkSomeWhereYouLove} link="https://corporate.target.com/careers"/>
        </CarouselCardContainer>
      </div>

      <div className={classes.mission_statment}>
        <MissionStatement />
      </div>

      <FeaturedPanel changeTopic={this.changeTopic} active_topic={this.state.current_topic}>
        <SubsiteContentCard title={"security subsite card"}
          topic={"security"}
          link={"/security"}
          description={"Take a look at Security at Target!"}/>
        <div/> {/* needs to be multiple for existing logic after I gutted this section */}


      </FeaturedPanel>
      {/* change to state but for now whatever*/}
      <AllProjects current_topic={this.state.current_topic}/>
      </div>
    )
  }
}

// <FeaturedContentCard title={"Captains Log"}
//   topic={"infra"}
//   link={"https://github.com/target/captains-log"}
//   description={"A Drone plugin that provides automated release notes. " +
//   "Providing seamless integrations between Github, Slack, and other issue trackers."}
//   image_source={CaptainsLogCarouselImage}/>



export default withStyles(styles, { withTheme: true })(Home);
