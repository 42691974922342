import React, { Component } from 'react';


// material ui components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

// constants
import { MISSION_STATEMENT } from '../../constants.js';

// styles
import { styles } from './MissionStatement.styles.js';
import withStyles from '@mui/styles/withStyles';


class MissionStatement extends Component {
  render(){
    const { classes } = this.props;

    return (
      <Card raised={true}>
          <CardHeader
            title={<Typography variant="h5" color="secondary" className={classes.text}> {"Target Open Source"} </Typography>}/>

          <CardContent>
          <Typography variant="body1">
            {MISSION_STATEMENT}
          </Typography>
          </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(MissionStatement);
