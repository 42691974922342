import React, { Component } from 'react';
import axios from 'axios';

// material ui core
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


// custom component
import AllProjectsContentCard from './AllProjectsContentCard.js';

// fallback api response
import { fullResponse } from './response';

import withStyles from '@mui/styles/withStyles';
import { styles } from './AllProjects.styles.js';

const backend = new Set(["backend", "openstack", "graphql"])
const frontend = new Set(["frontend", "javascript"])
const infra = new Set(["infra", "kubernetes", "docker", "spinnaker", "ci", "drone", "operations", "jenkins", "artifactory"])
const data = new Set(["data", "data-science", "data"])
const security = new Set(["cfc", "security"])
const vela = new Set(["vela", "golang", "elm"])

const controller = new AbortController();
class AllProjects extends Component {
  constructor(props) {
    super(props);
    this.check_if_topics_match_currect_topic = this.check_if_topics_match_currect_topic.bind(this);
    this.call_git_api = this.call_git_api.bind(this);
    this.state = {
      display_size: 6,
      display_array: [],
      data: []
    };
  }

  // currently two axios calls, should be changed to use the link to the next page on a loop.
  componentDidMount(){
    //this call waits for response
    this.call_git_api()
  }

  // check to see if current topic changed
  componentDidUpdate(prevProps){
    if(this.props.current_topic !== prevProps.current_topic)
    {
      this.setState({display_size: 6})
      this.load_display_array()
    }
  }

  componentWillUnmount(){
    controller.abort()
  }

  // 932489 - org id for target
  // 55509865 - org id for go-vela
  call_page(org_number, page_number){
    return axios
          .get('https://api.github.com/organizations/' + org_number + '/repos?page=' + page_number, { 'headers': { 'Accept': "application/vnd.github.mercy-preview+json" }, 'signal': controller.signal })
          .then(json => json.data.map(repo => ( (repo.fork === false && repo.archived ===false) ?
          {
              name: `${repo.name}`,
              description: `${repo.description}`,
              topics: `${repo.topics}`,
              url: `${repo.html_url}`
            } : ""
          )))
          .then(newData =>  this.setState({data: [...this.state.data, ...newData]}))
          .catch((err)=> {
            return this.loadOldData()
          })
  }

  // had to break the api call into parts so that we can wait for them in parallel (performance thing)
  async call_git_api(){

    //assigning a var may not be necessary - hard to test while rate limited.
    await Promise.all(
      [
        this.call_page(932489, 1), 
        this.call_page(932489, 2), 
        this.call_page(932489, 3), 
        this.call_page(55509865, 1),
        this.call_page(55509865, 2),
      ]
    )

    // have to run this check here to force us having the data.
    // create a second copy of array with just those we want.
    this.load_display_array()
  }

  viewMore(){
    // Simple, add 6 more unless there are less than 6 to add.
    // change this if you want to manipulate how many are shown at a time
    const number_to_add = 6;
    if((this.state.data.length - this.state.display_size) < number_to_add){
      this.setState({
        display_size: this.state.display_size + (this.state.data.length - this.state.display_size)
      })
    }
    else {
      this.setState({
        display_size: this.state.display_size + number_to_add
      })
    }
    this.setState({})
  }

  // api limit reached - use old response saved
  loadOldData(){
    if(controller.signal.aborted){return}
    console.log("API limit reached. Old data loaded")
    this.setState({data: fullResponse.map(repo => ( (repo.fork === false && repo.archived ===false) ?
      {
        name: `${repo.name}`,
        description: `${repo.description}`,
        topics: `${repo.topics}`,
        url: `${repo.html_url}`
      } : ""
    ))})
    this.load_display_array()

  }

  // problem is projects are tagged haphazardly and we want multiple
  // types of projects to fit likewise buckets. Go through each "bucket" aka backend frontent
  // and see if any of that projects topics/tags match that bucket's tags
  // if they do add that kind of bucket to an array that is returned which may have
  // 0 to 5 different types. We catch projects that are archived and forked as well
  sortTopics(topic_array){


    // this is for filtering out forked repos or any bad response
    if(topic_array == null){
      return "do_not_show"
    }

    // there are no topics tagged to that repo
    if(topic_array.length ===  0 )
    {
      return "unsorted"
    }


    // return value
    var matched_topics = []

    // turn it into a set
    var topic_set = new Set(topic_array.split(','));

    var backend_intersect = this.intersection(backend, topic_set)
    if(backend_intersect.size > 0){
      matched_topics.push("backend")
    }

    var frontend_intersect = this.intersection(frontend, topic_set)
    if(frontend_intersect.size > 0){
      matched_topics.push("frontend")
    }

    var infra_intersect = this.intersection(infra, topic_set)
    if(infra_intersect.size > 0){
      matched_topics.push("ci/cd")
    }

    var data_intersect = this.intersection(data, topic_set)
    if(data_intersect.size > 0){
      matched_topics.push("data")
    }

    var security_intersect = this.intersection(security, topic_set)
    if(security_intersect.size > 0){
      matched_topics.push("security")
    }

    var vela_intersect = this.intersection(vela, topic_set)
    if(vela_intersect.size > 0){
      matched_topics.push("vela")
    }

    if(matched_topics.length > 0){
      return matched_topics
    }
    else{
      return "unsorted"
    }

  }

  some_function_call(){}

  check_if_topics_match_currect_topic(topic_array){
    var matched_array = this.sortTopics(topic_array)

    if(matched_array.includes("do_not_show"))
    {
      return false
    }

    // if current_topic is all show it, otherwise only show if it matches current topic
    if(matched_array.includes(this.props.current_topic) || this.props.current_topic === "all"){
      return true
    }

    return false
  }

  // standard intestion function for two sets - helper for tagging projects
  intersection(setA, setB) {
    var _intersection = new Set();
    for (var elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem);
        }
    }
    return _intersection;
}

  load_display_array(){
    let temp_arr = []
    // load new data
    this.state.data.forEach(project =>
      {
        this.check_if_topics_match_currect_topic(project.topics) ?
          temp_arr.push(project): this.some_function_call()
    })
    if(!controller.signal.aborted){
      this.setState({display_array: temp_arr})
    }
  }

  render() {
    const { classes } = this.props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="stretch"
      spacing={4}
      className={classes.gridContainer}

      >
      { this.state.display_array.slice(0, this.state.display_size).map((project, i) =>

        {
          return(<Grid
           key={`all_project_${project.name}`}
           item xs={12} sm={6} md={4}
           className={classes.flex}>
           <AllProjectsContentCard
             title={project.name}
             description={project.description}
             link={project.url}
             />
         </Grid>)
       }
    )}
      <Grid item xs={9} sm={9} md={9} >
        <Button onClick={() => this.viewMore()} variant="contained" color="primary" className={classes.button} href={this.props.link}>
          <Typography className={classes.text} color="secondary" variant="h6">{"view more"}</Typography>
        </Button>
      </Grid>

    </Grid>
  )
  }
}

export default withStyles(styles)(AllProjects);
