export const styles = {
  root: {
    display: "flex",

    margin: 'auto',
    marginBottom: 25,
    marginTop: 45,
    width: "80%"
  },
  layout: {
    display: "flex",
    flexDirection: "row",

  },
  list: {
    display: "flex",
    flexWrap: "wrap",

    flexDirection: "row",
  },
  list_item: {
    display: "flex",
    maxWidth: 150,
    width: 150,
    alignItems: 'center',
  },
  test:{
    width: 1000,
    margin: "auto"
  },
  flex:{
    display: "flex"
  },
  text: {
    '&:hover': {
      color: "#CC0000"
    }
  },
  right: {
    width: "100%",
    margin: "auto"
  },
  align_top: {
    display: "flex",
    alignItems: "flex-start"
  },
  ['Mui-selected']: {
    background: `rgba(0,0,0,.08)`
  }
};
