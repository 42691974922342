import React, { Component } from 'react';

// material ui core
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import Octocat from './images/logo_octocat.png';
import withStyles from '@mui/styles/withStyles';
const styles = {
  root: {
    display: 'flex',
    margin: 15,
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    flexBasis: 0,
  },
  bottom: {
    justifyContent: 'flex-end',
  },
  button: {
    textTransform: "none",
  },
  text: {
    '&:hover': {
      color: 'red'
    }
  },
  title: {
    fontWeight: "bold",
    color: '#ff0000',
  },
  image: {
    display: "flex",
    margin: "auto",
    height: 100,
  },
};



class AllProjectsContentCard extends Component {
  render() {
    const { classes } = this.props;


  return (
    <Card raised={true} className={classes.root}>
        <CardHeader
          title={<Typography variant="h5" className={classes.title}>{this.props.title}</Typography>}
        />

        <CardContent>
          <img src={Octocat} alt="octocat" className={classes.image}/>
          <br/ >
          <Typography variant="body1"> {this.props.description} </Typography>
        </CardContent>


        <CardActions className={classes.bottom}>
          <Button className={classes.button} href={this.props.link}>
            <Typography className={classes.text} color="secondary" noWrap={true} variant="body2">{"view on github"}</Typography>
          </Button>
        </CardActions>


    </Card>
  )
}
}

export default withStyles(styles)(AllProjectsContentCard);
