import React, { Component } from 'react';

// material ui core
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CyberDev_Logo from './images/logo_cyberdev.png';

import withStyles from '@mui/styles/withStyles';

export const styles = {
  root: {
    marginTop: 50,
    marginBottom: 50,
    margin: 'auto',
    width: "80%",
  },
  title: {
  fontWeight: "bold",
  color: '#ff0000',
  },
  bold: {
    fontWeight: "bold"
  },
  image: {
  marginTop: 30,
  display: "flex",
  margin: "auto",
  height: 150
  },
}


class AboutCFC extends Component {
  render() {
    const { classes } = this.props;


  return (

    <Card raised={true} className={classes.root}>

      <CardHeader title={<Typography variant="h5" className={classes.title}>Target Cyber Fusion Center (CFC)</Typography>}/>

      <CardContent>
        {"The goal of the CFC space is to bring Target’s key information security teams together to work faster and with more agility than ever before. As a retailer, we bring an important perspective to the table in terms of Cyber Security and Incident Response. With cyber threats that continute to evolve and change on a daily basis, keeping our guests’ data secure and protecting our company information is an enormous job. It takes the right people working with top-notch technology to stay a step ahead—and that’s what our information security teams strive to do every day."}
        <br />
        <img src={CyberDev_Logo} alt="cfc" className={classes.image}/>
      </CardContent>
    </Card>
  )
}
}

export default withStyles(styles)(AboutCFC);
