export const CAREERS_LINK = "https://corporate.target.com/careers";
export const OPEN_SOURCE_LINK = "https://opensource.target.com/home";
export const GITHUB_LINK = "https://www.github.com/target";
export const DOJO_LINK = "https://tech.target.com/dojo";
export const PRIVACY_POLICY = "https://tech.target.com/privacy";
export const TERMS_AND_CONDITIONS = "https://www.target.com/c/terms-conditions/-/N-4sr7l";
export const LINKEDIN_LINK = "https://www.linkedin.com/company/target";
export const TWITTER_LINK = "https://www.twitter.com/target";
export const COPYRIGHT_NOTICE = "© 2023 Target Brands, Inc. Target and the Bullseye Design are registered trademarks of Target Brands, Inc.";
export const MISSION_STATEMENT = "Target is a retailer that increasingly uses technology to engage with our guests and help families discover the joy of everyday life.  A large part of Target's technology solutions rely on open source.   Target does not release its proprietary or market-differentiating technology solutions as open source.  Our engineering teams open source shared libraries, infrastructure, and tools that other companies may find useful.   We have a bias for releasing solutions that will be disruptive to software markets and for commoditizing software components that provide value and should be readily accessible to the engineering community."
