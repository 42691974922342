import React, { Component } from 'react';

// material ui components
import Card from '@mui/material/Card';
import CarouselButtons from './CarouselButtons.js';

// styles
import { styles } from './Carousel.styles.js';
import withStyles from '@mui/styles/withStyles';

class CarouselCardContainer extends Component {
  constructor(props) {
    super(props);
    this.slideLeft = this.slideLeft.bind(this);
    this.slideRight = this.slideRight.bind(this);
    this.goToSlide = this.goToSlide.bind(this);

    this.state = {
      active_card_index: 0,
      data: this.props.children,
      intervalId: 0
    };
  }

  componentDidMount(){
    var intervalId = setInterval(this.slideRight, 10000);
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount(){
    clearInterval(this.state.intervalId);
  }


  slideLeft(){
    if(this.state.active_card_index === 0){
      this.setState({active_card_index: this.state.data.length - 1})
    }
    else{
      this.setState({active_card_index: this.state.active_card_index - 1})
    }
  }

  slideRight(){
    if(this.state.active_card_index === this.state.data.length - 1){
      this.setState({active_card_index: 0})
    }
    else{
      this.setState({active_card_index: this.state.active_card_index + 1})
    }
  }

  goToSlide(e){
    this.setState({active_card_index: e});
  }

  render(){
    const { classes, children } = this.props;
    return (
      <div>

      <Card className={classes.height} raised={true}>
      {children[this.state.active_card_index]}

      <CarouselButtons left={this.slideLeft} right={this.slideRight} specific={this.goToSlide}
        total={children} active={this.state.active_card_index}/>
        </Card>
      </div>
    )
  }
}

export default withStyles(styles)(CarouselCardContainer);
