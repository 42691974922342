export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexBasis: 0,
  },
  left: {
    padding: 50,
    maxHeight: 250,
  },
  flex: {
    display: "flex"
  },

  bold: {
    fontWeight: 'bold'
  },
  text: {
    '&:hover': {
      color: 'red'
    }
  },
  button: {
    textTransform: "none",
  },
  image: {
    maxWidth: "60%",
    maxHeight: 250
  }
};
