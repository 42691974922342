import React, { Component } from 'react';

// material ui core
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';


import withStyles from '@mui/styles/withStyles';
import { styles } from './FeaturedContentCard.styles';



class SubsiteContentCard extends Component {
  render() {
    const { classes } = this.props;

  return (
    <Card raised={true} className={classes.root}>
          <CardHeader
          title={<Typography variant="h5" color="secondary" className={classes.bold}> {this.props.title} </Typography>}
          />


          <CardContent>
            <Typography variant="body1"> {this.props.description} </Typography>
          </CardContent>


          <CardActions className={classes.bottom}>
            <NavLink to={this.props.link}>
              <Typography className={classes.text} color="secondary" variant="body1">{"visit our website"}</Typography>
            </NavLink>
          </CardActions>


    </Card>
  )
}
}

export default withStyles(styles)(SubsiteContentCard);
