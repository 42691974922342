import React, { Component } from 'react';

// material ui components
import IconButton from '@mui/material/IconButton';

// icons
import GithubLogo from '../../static/github_logo.svg';
import LinkedInLogo from '../../static/linkin_logo.svg';
import TwitterLogo from '../../static/twitter_circle_white.svg';

// constants
import { TWITTER_LINK, LINKEDIN_LINK, GITHUB_LINK } from '../../constants.js';

// styles
import { styles } from './Footer.styles.js';
import withStyles from '@mui/styles/withStyles';

class FooterSocialMedia extends Component {
  render(){


    return (
      <div>
        <IconButton href={TWITTER_LINK} size="large">
           <img src={TwitterLogo} alt="twitter"/>
        </IconButton>
        <IconButton href={LINKEDIN_LINK} size="large">
           <img src={LinkedInLogo} alt="linkedin"/>
        </IconButton>
        <IconButton href={GITHUB_LINK} size="large">
           <img src={GithubLogo} alt="github"/>
        </IconButton>


      </div>
    );
  }
}

export default withStyles(styles)(FooterSocialMedia);
