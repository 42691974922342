import React, { Component } from 'react';

// material ui core
import Grid from '@mui/material/Grid';

// custom component
import AllProjectsContentCard from './AllProjectsContentCard.js';

import withStyles from '@mui/styles/withStyles';

const styles = {
  gridContainer: {
    display: 'flex',
    margin: 'auto',
    marginTop: 40,
    marginBottom: 40,
    width: "80%" ,
  },
  flex: {
    display: "flex"
  }
}

class AllProjects extends Component {
  render() {
    const { classes, content } = this.props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="stretch"
      className={classes.gridContainer}

      >
      {content.map((project) => {
        return (
          <Grid item xs={12} sm={6} md={4} className={classes.flex}
          key={`security_project_${project.name}`}>
            <AllProjectsContentCard
              title={project.name}
              description={project.description}
              link={project.link}
              />
          </Grid>
        )
      })}
    </Grid>
  )
  }
}

export default withStyles(styles)(AllProjects);
