export const styles = {
  root: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    flexBasis: 0,
  },
  bottom: {
    justifyContent: 'flex-end',
  },
  button: {
    textTransform: "none",
  },
  text: {
    '&:hover': {
      color: 'red'
    }
  },
  title: {
    fontWeight: "bold"
  },
  flex: {
    display: "flex",
    hyphens: "auto"
  },
};
