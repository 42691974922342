export const styles = {
  root: {
    padding: 50,
    marginTop: 40,

    background: 'black',
    display: 'flex',

  },
  text: {
    color: "white",
    '&:hover': {
      color: "#CC0000"
    }
  },
  bullseyeLogo: {
    width: 40
  },
  iconButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  gridContainer: {
    display: 'flex',
    margin: 'auto',
    width: "80%" ,
    alignItems: 'stretch',
  },
  center: {
    display: 'flex',
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  privacy: {
    display: 'flex',
    flexWrap: "wrap",
    justifyContent: "center"
  },
  button: {
    textTransform: "none",

  },
  flex: {
    display: "flex"
  },
  greyText: {
    color: "grey"
  },
  spacing: {
    margin: 5
  }

}
