export const styles = {
  root: {
    margin: "auto",
    marginTop: 35,
    width: "80%",
    marginBottom: 25,
  },
  controls: {
    display: "flex",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  bold: {
    fontWeight: 'bold'
  },
  icon: {
    padding: 6
  },
  height: {
    height: 300
  }
};
