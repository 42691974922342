import React, { Component } from 'react';

// styles
import { styles } from './ProjectImage.styles.js';
import withStyles from '@mui/styles/withStyles';


/*
Use for carousel when you want just an image. If you provide a link prop it will
be clickable, else it's just an image...
 */
class ProjectImage extends Component{
  render(){
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <a className={classes.center} href={this.props.link}>
          <img className={classes.image} src={this.props.source} alt=""/>
        </a>
      </div>
    )
  }
}

export default withStyles(styles)(ProjectImage);
