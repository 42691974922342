export const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 250
  },
  image: {
    maxHeight: 250,
    maxWidth: "100%",

  },
};
