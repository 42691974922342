import React, { Component } from 'react';

// material ui components
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';


// styles
import { styles } from './Footer.styles.js';
import withStyles from '@mui/styles/withStyles';

// constants
import { CAREERS_LINK, OPEN_SOURCE_LINK,
         GITHUB_LINK,
         COPYRIGHT_NOTICE
       } from "../../constants.js";

class FooterLinks extends Component {
  render(){
    const { classes } = this.props;


    return (
    <div >
        <div className={classes.center}>
          <Button href={CAREERS_LINK} className={classes.button} >
            <Typography className={classes.text} variant="h6">{"careers"}</Typography>
          </Button>
          <Button href={OPEN_SOURCE_LINK} className={classes.button}>
            <Typography className={classes.text} variant="h6">{"open source"}</Typography>
          </Button>
          <Button href={GITHUB_LINK} className={classes.button}>
            <Typography className={classes.text} variant="h6">{"target@github"}</Typography>
          </Button>
        </div>
        <div className={classes.privacy}>
          <NavLink to='/Privacy' className={classes.spacing}>
            <Typography className={classes.text} variant="caption">{"privacy policy"}</Typography>
          </NavLink>

          <NavLink to='/Terms' className={classes.spacing}>
            <Typography className={classes.text} variant="caption">{"terms & conditions"}</Typography>
          </NavLink>

        </div>
        <div className={classes.center}>
          <Typography className={classes.greyText}  variant="caption">{COPYRIGHT_NOTICE}</Typography>
        </div>
      </div>
    )
  }
}

//<Button href={PRIVACY_POLICY} className={classes.button}>
export default withStyles(styles)(FooterLinks);
