import React, { Component } from 'react';

import { Switch, Route } from 'react-router-dom';

import Home from '../../pages/Home.js';
import PrivacyPolicy from '../../pages/PrivacyPolicy.js';
import TermsAndConditions from '../../pages/TermsAndConditions.js';
import Health from '../../pages/Health.js';

import Security from '../../pages/Security/Security.js';

import withStyles from '@mui/styles/withStyles';
import { styles } from './PageRouting.styles.js';


class PageRouting extends Component {
  render(){
    return(
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/home' component={Home}></Route>
        <Route exact path='/Privacy' component={PrivacyPolicy}></Route>
        <Route exact path='/Terms' component={TermsAndConditions}></Route>
        <Route exact path='/Security' component={Security}></Route>
        <Route path='/health' component={Health}/>

      </Switch>
  )}
}

export default withStyles(styles, { withTheme: true })(PageRouting);
