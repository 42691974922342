export const styles = {
  root: {

  },
  carousel: {
    margin: "auto",
    marginTop: 25,
    width: "80%",
    marginBottom: 25,
  },
  mission_statment: {
    marginTop: 45,
    marginBottom: 25,
    width: "80%",
    margin: "auto"
  },
}
