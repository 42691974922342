import React, { Component } from 'react';

// material ui components
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';


// custom components
import FooterLinks from './FooterLinks.js';
import FooterSocialMedia from './FooterSocialMedia.js';

// images
import target_logo from "../../static/target_logo.svg";


// styles
import { styles } from './Footer.styles.js';
import withStyles from '@mui/styles/withStyles';




class Footer extends Component {
  render(){
    const { classes } = this.props;


    return (
      <footer className={classes.root}>

      {/*For large screens*/}
        <Hidden mdDown>

        <Grid container
          className={classes.gridContainer}
          justifyContent="space-between" >
            <Grid item xs={"auto"}>
              <IconButton className={classes.iconButton} href={"/"} size="large">
                <img className={classes.bullseyeLogo} src={target_logo}  alt="bullseye logo"/>
              </IconButton>
            </Grid>


          <Grid item xs={"auto"}>
            <FooterLinks/>
          </Grid>

          <Grid item xs={"auto"}>

            <FooterSocialMedia/>
          </Grid>
        </Grid>
      </Hidden>

      {/*For small screens*/}
        <Hidden mdUp>
        <Grid container
          className={classes.gridContainer}
          justifyContent="center" >

          <Grid item xs={"auto"}>
            <FooterLinks/>
          </Grid>

          <Grid item xs={"auto"}>

            <FooterSocialMedia/>
          </Grid>
        </Grid>
      </Hidden>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
