import React, { Component } from 'react';


// custom components
import SubsiteContentCard from './SubsiteContentCard.js';

import Navigation from './Navigation.js';

import withStyles from '@mui/styles/withStyles';
import { styles } from './FeaturedPanel.styles';


//The idea of this is that there are buttons on the left that are given a call
//  back function that sets the state to the index of that button.
//  I'm not in love how I handle passing in the content, but this works and is
// - basically just load in a CONST content and display s

class FeaturedPanel extends Component {
  constructor(props) {
    super(props);
    this.display_correct_featured_card = this.display_correct_featured_card.bind(this);
    this.state = {
      active_topic: 0,
      left: false,

    };
  }


  // be selective about what featured card is shown
  display_correct_featured_card(topic){
    // If we want to display everything
    if(this.props.active_topic === "all"){
      return true
    }

    // If topic of featured card matches active topic
    if(this.props.active_topic === topic){
      return true
    }

    // Don't show card
    return false
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {

      const { classes, children } = this.props;

    return (
      <div className={`${classes.root} ${classes.layout}`}>

        {/* LEFT */}
          {/* Pass in subsites card children only*/}
          <Navigation changeTopic={this.props.changeTopic} >
            {children.map(child =>
              {
                return child.type === SubsiteContentCard ? child : null
              }
            )}
          </Navigation>
        {/* RIGHT
          <div className={classes.right}>
              {
                children.map((featured_card) =>
                {  return this.display_correct_featured_card(featured_card.props.topic) ?
                  featured_card : true
                }
              )}

            </div>
            */}

      </div>
    )
  }

}

export default withStyles(styles)(FeaturedPanel);
