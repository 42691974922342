import React, { Component } from 'react';

// material ui core
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import withStyles from '@mui/styles/withStyles';
import { styles } from './FeaturedPanel.styles';

const topics = ["all", "infra", "data", "frontend", "backend", "vela"]

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.handleTopicChange = this.handleTopicChange.bind(this);
    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.state = {
      left: false,
      selectedIndex: 0,

    };
  }

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index });
  };

  handleTopicChange(topic, event, index) {
    this.handleListItemClick(event, index)
    this.props.changeTopic(topic);
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render(){

    const { classes, children } = this.props;

    return (
    <div className={classes.test}>

{/*      <Hidden xsDown>
*/}
        <List className={classes.list}>
          {/* Display all the standard topics*/}
          {topics.map((tag, i) => {
            return(
              <ListItemButton
                className={classes.list_item}
                key={`topic_${tag}`}
                selected={this.state.selectedIndex === i}
                onClick={event => this.handleTopicChange(tag, event, i)}>
                <ListItemText primary={<Typography variant="h5" className={classes.text}> {tag} </Typography>} />
              </ListItemButton>
            )
          })}
          <Divider />
          {/* Display all the subsite children cards */}
          {children.map((child, i) =>
            {
              return  child !== null ?
              <ListItemButton
                className={classes.list_item}
                key={`topic_${child.props.topic}`}
                selected={this.state.selectedIndex === i+topics.length}
                button={true}
                onClick={event => this.handleTopicChange(child.props.topic, event, i+topics.length)}>
                <ListItemText primary={<Typography variant="h5" className={classes.text}> {child.props.topic} </Typography>} />
              </ListItemButton>
              : ""

            }
          )}

        </List>
{/*      </Hidden>
*/}
    {/*  <Hidden smUp className={classes.flex}>

        <SwipeableDrawer
                  disableSwipeToOpen={false}
                  open={this.state.left}
                  onClose={this.toggleDrawer('left', false)}
                  onOpen={this.toggleDrawer('left', true)}
        >
        <List>
          {topics.map((tag, i) => {
            return(
              <ListItem
                key={i}
                button={true}
                onClick={() => this.handleTopicChange(tag)}>
                <ListItemText primary={<Typography variant="h5" className={classes.text}> {tag} </Typography>} />
              </ListItem>
            )
          })}
          <Divider />
          {children.map((child, i) =>
            {
              return  child !== null ?
              <ListItem
                key={i}
                button={true}
                onClick={() => this.handleTopicChange(child.props.topic)}>
                <ListItemText primary={<Typography variant="h5" className={classes.text}> {child.props.topic} </Typography>} />
              </ListItem>
              : ""

            }
          )}

        </List>
        </SwipeableDrawer>

      </Hidden>*/}
    </div>
  )
  }
}


export default withStyles(styles)(Navigation);
