import React, { Component } from 'react';


class Health extends Component{
  render(){
    return(
      <div>
      Healthy!
      </div>
    )
  }
}

export default Health;
