export const styles = theme => ({
  appBar: {
    position: "sticky",

  },
  bullseyeLogo: {
    width: 40
  },
  navButtons: {
    direction: 'rtl'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  largeSpacing: {
    margin: "auto",
    width: "80%"

  },
  smallSpacing: {
    margin: "auto",
    width: "100%"
  }

});
