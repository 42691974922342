import React, { Component } from "react"

import Footer from "./components/Footer/Footer.js"
import NavBar from "./components/NavBar/NavBar.js"

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { createTheme } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"

import PageRouting from "./components/PageRouting/PageRouting.js"

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#CC0000",
    },
    background: {
      default: "#EEE",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    // for some reason this was not using the correct color. it was blending into the background too much so now we shade darker instead of shade lighter
    'MuiListItemButton': {
      styleOverrides: {
        root: {
            '&.Mui-selected': {
              backgroundColor: 'rgba(0,0,0,.08)'
          }
        }
      }
    }
  }
})

class App extends Component {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <NavBar />

          <PageRouting />

          <Footer />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App
