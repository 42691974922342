import React, { Component } from 'react';

// material ui components
import IconButton from '@mui/material/IconButton';


// icons
import RightArrow from '../../static/right_arrow.svg';
import LeftArrow from '../../static/left_arrow.svg';
import UnfilledBox from '../../static/unfilled_box.svg';
import FilledBox from '../../static/filled_box.svg';

// styles
import { styles } from './Carousel.styles.js';
import withStyles from '@mui/styles/withStyles';

class CarouselButtons extends Component {
  constructor(props) {
    super(props);
    this.slideLeft = this.slideLeft.bind(this);
    this.slideRight = this.slideRight.bind(this);
    this.goToSlide = this.goToSlide.bind(this);
  }

  slideRight(){
    this.props.right()
  }

  slideLeft(){
    this.props.left();
  }

  goToSlide(e){
    this.props.specific(e);
  }

  render(){
    const { classes } = this.props;
    return (
      <div className={classes.controls}>
        <IconButton onClick={() => this.slideLeft()} size="large">
          <img src={LeftArrow} alt=""/>
        </IconButton>

      {this.props.total.map((slide, i) => {
        if(this.props.active === i){
          return (
            <IconButton
              key={i}
              className={classes.icon}
              onClick={() => this.goToSlide(i)}
              size="large">
              <img src={FilledBox} alt=""/>
            </IconButton>
          );}
        else{
          return (
            <IconButton
              key={i}
              className={classes.icon}
              onClick={() => this.goToSlide(i)}
              size="large">
              <img src={UnfilledBox} alt=""/>
            </IconButton>
          );
        }
        }

      )
      }

      <IconButton onClick={() => this.slideRight()} size="large">
        <img src={RightArrow} alt=""/>
      </IconButton>

      </div>
    );
  }
}

export default withStyles(styles)(CarouselButtons);
