/*
Simple follow the pattern provided here for now - working on creating a better solution for
adding content.
*/
export const featured_content = [{
  topic: "Projects",
  topic_description: "A long description of what this topic is (e.g. Analysis). It should be a few sentences long and be enough to give a good overview of how it works at target ",
  projects: [
    {
      name: "Strelka",
      description: "Strelka is a real-time file scanning system used for threat hunting, threat detection, and incident response. ",
      link: "https://github.com/target/strelka",
      image_source: "/images/logo_strelka.jpg"
    },
    {
      name: "Huntlib",
      description: "A Python library to help with some common threat hunting data analysis operations using Pandas from Elasticsearch or Splunk data",
      link: "https://github.com/target/huntlib",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
    {
      name: "Halogen",
      description: "Halogen is a tool to automate the creation of yara rules against image files embedded within a malicious document.",
      link: "https://github.com/target/halogen",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
    {
      name: "hunt_powershell_obfuscation",
      description: "Research and analysis tool that helps cyber threat hunters to find obfuscated Powershell commands.",
      link: "https://github.com/target/hunt-powershell-obfuscation",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
    {
      name: "Attack-navigator-docker",
      description: " A simple Docker container that serves the MITRE ATT&CK Navigator web application.",
      link: "https://github.com/target/attack-navigator-docker",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
    {
      name: "Schema-Check-filter-for-Logstash",
      description: "This plugin is to be used with the Logstash system. It is used to check whether an event is compliant with a provided schema.",
      link: "https://github.com/target/Schema-Check-filter-for-Logstash",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
    {
      name: "Strelka UI",
      description: "Browse / API based file submission frontend for the Strelka Enterprise File Scanner.",
      link: "https://github.com/target/strelka-ui",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
    {
      name: "Merry Maker",
      description: "A fully scalable tool to detect the presence of digital skimmers.",
      link: "https://github.com/target/mmk-ui-api",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
    {
      name: "JS-Scope (Merry Maker Plugin)",
      description: "Enumerates javascript requests and hooks native function calls with headless Chrome.",
      link: "https://github.com/target/mmk-js-scope",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
    {
      name: "Shared Types (Merry Maker Plugin)",
      description: "Shared Typescript Typings for the MerryMaker platform.",
      link: "https://github.com/target/mmk-types",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
  ]
},
/*
{
  topic: "Example Topic 2",
  topic_description: "A long description of what this topic is (e.g. Data). It should be a few sentences long and be enough to give a good overview of how it works at target ",
  projects: [
    {
      name: "Example Featured Project 1",
      description: "The description for a featured project card should be a good long write up. It should be more than a simple description and inspire the reader to get involved",
      link: "https://www.github.com",
      image_source: "./content/images/BullseyeLogo.jpg"
    },
  ]
},
*/
];


export const all_projects_content = [
  {
    name: "Strelka",
    description: "Strelka is a real-time file scanning system used for threat hunting, threat detection, and incident response. ",
    link: "https://github.com/target/strelka",
  },
  {
    name: "Strelka UI",
    description: "Browse / API based file submission frontend for the Strelka Enterprise File Scanner.",
    link: "https://github.com/target/strelka-ui",
    image_source: "./content/images/BullseyeLogo.jpg"
  },
  {
    name: "Huntlib",
    description: "A Python library to help with some common threat hunting data analysis operations using Pandas from Elasticsearch or Splunk data",
    link: "https://github.com/target/huntlib"
  },
  {
    name: "Halogen",
    description: "Halogen is a tool to automate the creation of yara rules against image files embedded within a malicious document.",
    link: "https://github.com/target/halogen",
  },
  {
    name: "hunt_powershell_obfuscation",
    description: "Research and analysis tool that helps cyber threat hunters to find obfuscated Powershell commands.",
    link: "https://github.com/target/hunt-powershell-obfuscation",
  },
  {
    name: "Attack-navigator-docker",
    description: " A simple Docker container that serves the MITRE ATT&CK Navigator web application.",
    link: "https://github.com/target/attack-navigator-docker"
  },
  {
    name: "Schema-Check-filter-for-Logstash",
    description: "This plugin is to be used with the Logstash system. It is used to check whether an event is compliant with a provided schema.",
    link: "https://github.com/target/Schema-Check-filter-for-Logstash"
  },
  {
    name: "Merry Maker",
    description: "A fully scalable tool to detect the presence of digital skimmers.",
    link: "https://github.com/target/mmk-ui-api",
    image_source: "./content/images/BullseyeLogo.jpg"
  },
  {
    name: "JS-Scope (Merry Maker Plugin)",
    description: "Enumerates javascript requests and hooks native function calls with headless Chrome.",
    link: "https://github.com/target/mmk-js-scope",
    image_source: "./content/images/BullseyeLogo.jpg"
  },
  {
    name: "Shared Types (Merry Maker Plugin)",
    description: "Shared Typescript Typings for the MerryMaker platform.",
    link: "https://github.com/target/mmk-types",
    image_source: "./content/images/BullseyeLogo.jpg"
  },
  {
    name: "Coming soon!",
    description: "We're committed to contributing to the community. For now, check out other open sourced projects from Target!",
    link: "https://github.com/target/"
  },
];
