import React, { Component } from 'react';

// material ui core
import Typography from '@mui/material/Typography';

import withStyles from '@mui/styles/withStyles';
const styles = {
  container: {
    display: 'flex',
    flexDirection: "column",
    margin: 'auto',
    width: "100%" ,
    alignItems: 'stretch',
  },
  bold: {
    marginBottom: 30,
    fontWeight: "bold"
  }
}


class ContactUs extends Component {
  render() {
    const { classes } = this.props;


  return (
    <div className={classes.container}>
      <Typography color="inherit" align="center" variant="h5" className={classes.bold}>
        contact us
      </Typography>

      <Typography color="inherit" align="center" variant="body1" className={classes.bold}>
        <a href="mailto:TTS-CFC-OpenSource@target.com"> TTS-CFC-OpenSource@target.com</a>
      </Typography>
    </div>
  )
}
}

export default withStyles(styles)(ContactUs);
