import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    maxWidth: 700,
    margin: "auto"
  },
}
class ScrollToTop extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate () {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

class PrivacyPolicy extends Component{
  render(){
    const { classes } = this.props;


    return(
    <div className={classes.root}>
    <ScrollToTop />
    <Typography variant="h4"  align="center" gutterBottom={true}>
    opensource.target.com Privacy Policy
    </Typography>

    <Typography>
    At Target, we want you to know how we collect, use, share and protect information about you. This privacy policy applies to your access and use of the opensource.target.com blog.
    </Typography>

    <Typography variant="h4"  align="center" gutterBottom={true}>
    What Information is Collected?
    </Typography>

    <Typography>
    We automatically collect information about your use of opensource.target.com, including:<br/>
    - Utilization data, e.g. # of users of opensource.target.com<br/>
    - Session data, e.g. date and time device entered, length of session<br/>
    - Type of device, e.g. iPhone, Samsung tablet<br/>
    - Browser data, e.g. browser version, IP address<br/>
    - Device data, e.g. Device ID number, MAC address<br/>
    </Typography>


    <Typography variant="h4"  align="center" gutterBottom={true}>
    How is This Information Used?
    </Typography>

    <Typography>
    Ways we use the information we collect include:<br/>
    - Internal operations, e.g. enhancing the effectiveness of opensource.target.com, analyzing how opensource.target.com is used and improving our site experience<br/>
    - Legal compliance, e.g. assist law enforcement and respond to legal/regulatory inquiries<br/>
    </Typography>

    <Typography variant="h4"  align="center" gutterBottom={true}>
    How is This Information Shared?
    </Typography>

    <Typography>
    We may share the information we collect within Target Corporation, which includes all Target subsidiaries and affiliates. We may share information with service providers whose companies provide support services to us and need information about your use of opensource.target.com to perform their functions. We may disclose your information when we believe disclosure is appropriate to comply with the law; apply applicable terms and conditions and other agreements; or to protect the rights, property or safety of our company, our guests or others. other web site analytics services Analytics services such as Google Analytics provide services that analyze information regarding visits to our websites and mobile applications. They use cookies, web beacons, and other tracking mechanisms to collect this information.
    </Typography>

    <Typography variant="caption">
    <br/>To learn more about Google’s privacy practices, <a href="https://policies.google.com/privacy">click here</a>. To access and use the Google Analytics Opt-out Browser Add-on, <a href="https://tools.google.com/dlpage/gaoptout">click here</a>.
    </Typography>

    <Typography variant="h4"  align="center" gutterBottom={true}>
    How is this Information Protected?
    </Typography>

    <Typography>
    We maintain administrative, technical and physical safeguards to protect your information.
    </Typography>

    <Typography variant="h4"  align="center" gutterBottom={true}>
    How Do You Contact Target?
    </Typography>

    <Typography>
    - Mail: Target HQ Locations, Target Executive Offices, P.O. Box 9350, Minneapolis, MN 55440<br/>
    - Phone: 800-440-0680<br/>
    - Email: <a href="mailto:guest.relations@target.com">guest.relations@target.com</a><br/>
    </Typography>

    <Typography variant="caption">
    last update: 1/17/2019
    </Typography>

    </div>)
  }
}

export default withStyles(styles)(PrivacyPolicy);
