import React, { Component } from 'react';

// material ui core
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import withStyles from '@mui/styles/withStyles';
import { styles } from './AllProjectsContentCard.styles.js';



class AllProjectsContentCard extends Component {
  render() {
    const { classes } = this.props;


  return (
    <Card raised={true} className={classes.root}>

        <CardHeader className={classes.flex}
          title={<Typography noWrap={false} variant="h5" className={classes.title}>{this.props.title}</Typography>}
        />

        <CardContent>
          <Typography variant="body1"> {this.props.description} </Typography>
        </CardContent>


        <CardActions className={classes.bottom}>
          <Button className={classes.button} href={this.props.link}>
            <Typography className={classes.text} color="secondary" variant="body1">{"view on github"}</Typography>
          </Button>
        </CardActions>


    </Card>
  )
}
}

export default withStyles(styles)(AllProjectsContentCard);
